import { Router, createRouter as _createRouter, createWebHistory } from 'vue-router'

const createRouter = (): Router => {
  return _createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
      {
        path: '/auth/callback',
        name: 'auth-callback',
        component: import('@/components/LoadingBlock.vue'),
        meta: {
          skipAuthentication: true,
        },
      },
      {
        path: '/',
        name: 'timeline',
        components: {
          header: () => import('@/views/Header.vue'),
          main: () => import('@/views/search/Search.vue'),
          leftAside: () => import('@/views/LeftAside.vue'),
        },
      },
      {
        path: '/claim-vessel/:step',
        name: 'claim-vessel',
        components: {
          header: () => import('@/views/Header.vue'),
          main: () => import('@/pages/ClaimVessel.vue'),
          leftAside: () => import('@/views/LeftAside.vue'),
        },
        props: {
          main: (route) => ({ step: route.params.step }),
        },
      },
      {
        path: '/edit-vessel/:uri',
        name: 'edit-vessel',
        components: {
          header: () => import('@/views/Header.vue'),
          main: () => import('@/pages/EditVessel.vue'),
          leftAside: () => import('@/views/LeftAside.vue'),
        },
        props: {
          main: (route) => ({
            uri: decodeURIComponent(route.params.uri.toString()),
          }),
        },
      },
      {
        path: '/view-vessel/:uri',
        name: 'view-vessel',
        components: {
          header: () => import('@/views/Header.vue'),
          main: () => import('@/pages/ViewVessel.vue'),
          leftAside: () => import('@/views/LeftAside.vue'),
        },
        props: {
          main: (route) => ({
            uri: decodeURIComponent(route.params.uri.toString()),
          }),
        },
      },
      // {
      //   path: '/calendar',
      //   name: 'calendar',
      //   components: {
      //     header: () => import('@/views/Header.vue'),
      //     navbar: () => import('@/views/Navbar.vue'),
      //     main: () => import('@/views/Calendar.vue'),
      //   },
      // },
      {
        path: '/feed/specials',
        name: 'specials',
        components: {
          header: () => import('@/views/Header.vue'),
          main: () => import('@/views/feed/Specials.vue'),
          leftAside: () => import('@/views/LeftAside.vue'),
        },
      },
      {
        path: '/feed/charter-requests',
        name: 'charter-requests',
        components: {
          header: () => import('@/views/Header.vue'),
          main: () => import('@/views/feed/CharterRequests.vue'),
          leftAside: () => import('@/views/LeftAside.vue'),
        },
      },
      {
        path: '/feed/my-posts',
        name: 'my-posts',
        components: {
          header: () => import('@/views/Header.vue'),
          main: () => import('@/views/feed/MyPosts.vue'),
          leftAside: () => import('@/views/LeftAside.vue'),
        },
      },
      {
        path: '/feed/company-posts',
        name: 'company-posts',
        components: {
          header: () => import('@/views/Header.vue'),
          main: () => import('@/views/feed/MyCompanyPosts.vue'),
          leftAside: () => import('@/views/LeftAside.vue'),
        },
      },
      {
        path: '/settings',
        name: 'settings',
        components: {
          header: () => import('@/views/Header.vue'),
          main: () => import('@/views/Settings.vue'),
          leftAside: () => import('@/views/LeftAside.vue'),
        },
      },
      {
        path: '/unauthorized',
        name: 'unauthorized',
        components: {
          main: () => import('@/views/Unauthorized.vue'),
        },
        meta: {
          skipAuthentication: true,
        },
      },
      {
        path: '/forbidden',
        name: 'forbidden',
        components: {
          main: () => import('@/views/Forbidden.vue'),
        },
        meta: {
          skipAuthentication: true,
        },
      },
      {
        path: '/:pathMatch(.*)*',
        name: '404',
        components: {
          main: () => import('@/views/NotFound.vue'),
        },
        meta: {
          skipAuthentication: true,
        },
      },
    ],
  })
}

export default createRouter
